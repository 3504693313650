import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import t from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import React from 'react';
import VariantDropdown from '../ProductPage/VariantSelector/VariantDropdown';

export function SelectVariant({ product }) {
  const { update } = useProductList();
  const initialVariant = product.variant;

  const variantHandler = useProductVariants(product, {
    initialVariant
  });

  const { selectedVariant } = variantHandler;

  React.useEffect(() => {
    // When a variant is selected, call the update function from useProductList to update the list
    function setVariant(variantArticleNumber) {
      update(product.articleNumber, {
        variantArticleNumber,
        variantToReplace: product.variant?.articleNumber,
        quantity: 1
      });
    }

    const currentArticleNumber = product.variant?.articleNumber;

    if (!selectedVariant) return;

    if (currentArticleNumber !== selectedVariant.articleNumber) {
      setVariant(selectedVariant.articleNumber);
    }
  }, [product.articleNumber, product.variant, selectedVariant, update]);

  return (
    <>
      {product.variants.options.map(option => {
        return (
          <VariantDropdown
            key={option.name}
            option={option}
            product={product}
            variantHandler={variantHandler}
            showImages={false}
          />
        );
      })}
    </>
  );
}
