import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import Image from '@jetshop/ui/Image';

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 45%;
      text-align: center;
    }
    ${theme.above.lg} {
      width: 25%;
      padding: 3rem 1.5rem;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

export const FooterBottom = styled('div')`
  > ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    ${theme.below.md} {
      flex-direction: column;
      align-items: center;
    }
    > li {
      margin: 5px;
    }
  }
`;

export const FooterLogo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  background: ${theme.footer.logoWrapper.background};
  > div {
    width: 100%;
    max-width: 400px;
  }
`;

const FooterContent = () => {
  const {
    footer1,
    footer2,
    footer3,
    footer4,
    footerBottom,
    footerLogo
  } = useContext(SiteContentContext);
  if (!footer1) return null;
  return (
    <>
      <FooterContentWrapper>
        <div>{footer1}</div>
        <div>{footer2}</div>
        <div>{footer3}</div>
        <div>{footer4}</div>
      </FooterContentWrapper>
      <FooterBottom>{footerBottom}</FooterBottom>
      <FooterLogo>
        <div>
          <Image aspect="2:1" src={footerLogo} />
        </div>
      </FooterLogo>
    </>
  );
};

export default FooterContent;
