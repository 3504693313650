import React, { useContext } from 'react';
import Image from '@jetshop/ui/Image';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const Logo = ({ secondary = false, size = [400] }) => {
  const { logoType } = useContext(SiteContentContext);
  return <Image src={logoType} sizes={size} aspect={'300:100'} crop={false} />;
};

export default Logo;
