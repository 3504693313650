export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter'
    },
    brand: {
      id: 154
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Shirtstore',
    siteContentId: {
      Sweden: 190,
      Denmark: 190,
      England: 190,
      Finland: 190,
      Norway: 190,
      Poland: 190,
      B2Bse: 191,
      B2Beu: 192
    }
  }
};
