import React, { useRef, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Carot } from '@jetshop/ui/svg/Carrot.svg';
import { theme } from '../Theming/Theming';
import { Above } from '@jetshop/ui/Breakpoints';

const SliderBoxWrapper = styled('div')`
  position: relative;
  .slider-track {
    overflow-x: auto;
    /* width */
    ::-webkit-scrollbar {
      height: 0px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #fff;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
  .slider-items {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    > * {
      flex-shrink: 0;
    }
  }
  &.gradient {
    &.prev:before {
      background: linear-gradient(
        to right,
        white 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      height: 100%;
      position: absolute;
      width: 5px;
      z-index: 2;
      left: 0px;
      top: 0px;
    }
    &.next:after {
      background: linear-gradient(
        to left,
        white 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      height: 100%;
      position: absolute;
      width: 5px;
      z-index: 2;
      right: 0px;
      top: 0px;
    }
  }
`;

const ButtonWrapper = styled('button')`
  position: absolute;
  width: 24px;
  height: 24px;
  &.standard-nav {
    top: calc(50% - 12px);
  }
  &.bottom-nav {
    bottom: 0;
  }
  svg {
    font-size: 9px;
  }
  ${theme.above.xl} {
    &.standard-nav {
      top: calc(50% - 15px);
    }
    width: 30px;
    height: 30px;
    svg {
      font-size: 11px;
    }
  }
  box-shadow: 0px 0px 9px #00000026;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  opacity: 1;
  &:hover {
    //opacity: 0.95;
  }

  &.next {
    right: -5px;
    svg {
      transform: rotate(-90deg);
    }
  }
  &.prev {
    left: -5px;
    svg {
      transform: rotate(90deg);
      position: relative;
      left: -1px;
    }
  }
`;

const SliderButton = ({ dir, className, ...rest }) => (
  <ButtonWrapper
    className={`${dir === 'next' ? 'next' : 'prev'} ${className}`}
    {...rest}
  >
    <Carot />
  </ButtonWrapper>
);

const NavButtons = ({
  showNavInMobile,
  scrollContainerBy,
  next,
  prev,
  className = ''
}) => (
  <Above breakpoint="lg">
    {matches => {
      if (matches || showNavInMobile)
        return (
          <div className="nav-buttons">
            {prev && (
              <SliderButton
                className={`${className}`}
                dir={'prev'}
                onClick={() => {
                  scrollContainerBy(true);
                }}
              />
            )}

            {next && (
              <SliderButton
                className={`${className}`}
                dir={'next'}
                onClick={() => {
                  scrollContainerBy();
                }}
              />
            )}
          </div>
        );
      return null;
    }}
  </Above>
);

export const ScrollBox = ({
  children,
  gradient = true,
  showNavInMobile = false,
  bottomNav = false
}) => {
  const sliderRef = useRef(null);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(false);

  useEffect(() => {
    //INITIAL
    if (
      !prev &&
      sliderRef.current.scrollWidth > sliderRef.current.offsetWidth
    ) {
      setNext(true);
    }

    sliderRef.current.addEventListener('scroll', handleScroll);
    return () => {
      sliderRef.current.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = event => {
    if (!prev && event.target.scrollLeft > 0) setPrev(true);

    if (prev && event.target.scrollLeft === 0) setPrev(false);

    if (
      next &&
      event.target.scrollLeft + event.target.offsetWidth ===
        event.target.scrollWidth
    )
      setNext(false);
    if (
      !next &&
      event.target.scrollLeft + event.target.offsetWidth <
        event.target.scrollWidth
    )
      setNext(true);
  };

  function scrollContainerBy(back = false) {
    const width = sliderRef.current.offsetWidth / 2;
    const distance = back ? -width : width;
    sliderRef.current.scrollBy({ left: distance, behavior: 'smooth' });
  }

  let cssClass = gradient ? 'gradient' : '';
  cssClass += prev ? ' prev' : '';
  cssClass += next ? ' next' : '';

  const navProps = {
    ...{
      scrollContainerBy,
      showNavInMobile,
      next,
      prev
    }
  };

  return (
    <SliderBoxWrapper className={`slider-box-wrapper ${cssClass}`}>
      <NavButtons {...navProps} className="standard-nav" />
      <div className="slider-track" ref={sliderRef}>
        <div className="slider-items">{children}</div>
      </div>
      {bottomNav && <NavButtons {...navProps} className="bottom-nav" />}
    </SliderBoxWrapper>
  );
};

/*
USAGE
<ScrollSlider scrollDistance={600} direction="vertical">
  {products.map((product, index) => (
    <ProductCard
      product={product}
      key={product.id}
    />
  ))}
</ScrollSlider>
*/
