import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { ReactComponent as Heart } from '../../../svg/heart-solid.svg';
import { styled } from 'linaria/react';

const StyledHeart = styled(Heart)`
  width: 1em;
`;

export default ({ className = '', active, ...rest }) => {
  if (!active)
    return <FontAwesomeIcon icon={faHeart} {...rest} className={className} />;
  else return <StyledHeart {...rest} className={className} />;
};
