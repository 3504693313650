import t from '@jetshop/intl';
import React, { useContext } from 'react';
import { css } from 'linaria';
import { ProductCard } from '../CategoryPage/ProductCard';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import CloseButton from '../ui/CloseButton';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import B2BContext from '../Util/B2BContext';

export function Product({ product, loading, children }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };

  const { enablePurchase } = useContext(B2BContext);
  if (
    product.isVariant &&
    product.variant &&
    product.variant.images &&
    product.variant.images.length
  ) {
    productForDisplay.images = product.variant.images;
  }

  return (
    <ProductCard
      product={productForDisplay}
      showFavorite={false}
      showVariants={false}
      as="div"
    >
      {product.hasVariants && (
        <div className="select-variant">
          <SelectVariant product={product} />
        </div>
      )}

      {enablePurchase && (
        <>
          {product.validation.status === 'valid' ? (
            <AddToCart product={product} />
          ) : (
            <InvalidProduct validation={product.validation} />
          )}
        </>
      )}

      <RemoveFromList
        articleNumber={product.articleNumber}
        variant={product.variant}
      />
    </ProductCard>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <div
      className={removeStyles}
      onClick={() => remove(articleNumber, { variantArticleNumber })}
    >
      <CloseButton />
    </div>
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Pre-order date required'),
    configurations: t('Visit product page to add'),
    package: t('Visit product page to add')
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}

const removeStyles = css`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.5em;
  z-index: 99;
  > button {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }
  svg {
    height: 1.25em;
    width: 1.25em;
  }
`;

const invalidProductStyles = css`
  background: #e7e7e7;
  height: 54px;
  text-align: center;
  padding: 0 1em;
  color: #555555;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
