import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { styled } from 'linaria/react';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)``;

export default ({ className = '', ...rest }) => {
  return (
    <StyledFontAwesomeIcon icon={faSearch} {...rest} className={className} />
  );
};
