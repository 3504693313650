import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theming/Theming';

const ColorWrapper = styled('div')`
  &.circle {
    box-shadow: 0px 0px 3px 0px #0000001c;
    border-radius: 50%;
    overflow: hidden;
  }
`;

export const Color = ({
  value,
  size = 20,
  className = '',
  aspect = '1:1',
  crop
}) => {
  const colorName = value && value.replace(/[^a-z0-9]/gi, '').toLowerCase();
  return (
    <ColorWrapper
      className={cx('color-circle', className)}
      style={{ width: size + 'px' }}
    >
      <Image
        aspect={aspect}
        crop={crop}
        cover={crop}
        src={`/pub_docs/files/colors/${colorName}.png`}
        sizes={[size]}
        quality={70}
      />
    </ColorWrapper>
  );
};
