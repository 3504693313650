import React from 'react';
import { styled } from 'linaria/react';
import { Color } from './Color';
import PlusIcon from '../../ui/icons/PlusIcon';

//STYLED COMPONENTS
const ColorCircleWrapper = styled('div')`
  position: relative;
`;

const ColorCirclesWrapper = styled('div')`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  height: 14px;
  position: relative;
  ${ColorCircleWrapper} {
    margin: 0 2px 2px;
  }
`;

const MoreColors = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 5px;
  svg {
    color: #aaa;
  }
`;

//HELPERS
export function colorName(name) {
  return name.toLowerCase() === 'colour' || name.toLowerCase() === 'color';
}

export function checkForColor(product) {
  return product.variants.options.filter(option => colorName(option.name))[0];
}

export function getSecondOption(product) {
  return product.variants.options.filter(option => !colorName(option.name))[0];
}

//CIRCLE COMPONENT
const ColorCircle = ({ value, option }) => {
  return (
    <ColorCircleWrapper>
      <Color value={value} className="circle" size={14} />
    </ColorCircleWrapper>
  );
};

//ALL CIRCLES COMPONENT
export const ColorCircles = ({ product }) => {
  const colorOption = checkForColor(product);

  if (!colorOption) return <ColorCirclesWrapper></ColorCirclesWrapper>;

  const colorAmount = 7;

  //SHOW ONLY FIRST {colorAmount} COLORS
  const moreColors =
    colorOption.values && colorOption.values.length > colorAmount;

  if (colorOption) {
    return (
      <ColorCirclesWrapper>
        {colorOption.values.map((value, index) => {
          if (index < colorAmount)
            return (
              <ColorCircle key={index} value={value} option={colorOption} />
            );
          return null;
        })}
        {moreColors && (
          <MoreColors>
            <PlusIcon />
          </MoreColors>
        )}
      </ColorCirclesWrapper>
    );
  } else return null;
};
